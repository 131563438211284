<template>
    <div class="container">
        
        <div v-if="!inOrder">
            <div v-if="title" class="title-container">
                <h2 class="title text-3xl font-extrabold">{{ title }}</h2>
            </div>

            <div v-if="subTitle" class="title-subcontainer">
                <h4 class="font-semibold color-purple text-xl">{{ subTitle }}</h4>
            </div>
            <ul v-for="row in list" :key="row" class="list">
                <li class="list-item flex font-light" v-html="row"></li>
            </ul>
        </div>

        <div v-if="inOrder">
            <h3 class="title">{{ title }}</h3>
            <ol v-for="row in list" :key="row" class="list-group list-group-flush">
                <li class="list-group-item">{{ row }}</li>
            </ol>
        </div>

    </div>
</template>

<script>
export default {
    props: [ "title", "subTitle", "list", "inOrder" ]
}
</script>

<style scoped >
    .title{
        position:relative;
        display:inline-block;
        color:#810042!important;
    }

    .title:after {
        content: '';
        position: absolute;
        left: 0px;
        top: 65%;
        margin: 10px auto;
        width: 34%;
        height: 4px;
        background: #FDCE00;
        margin-top: 20px;
    }

    .title-container {
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
        margin-bottom: 50px;
    }

    li.list-item {
        padding-bottom: 5px;
        border-width: 1.5px;
        padding-top: 5px;
        word-wrap: break-Word;
        border-left: 0px;
        border-right: 0px;
        border-top: 0px;
        border-bottom: 0px;
    }

    ol, ul {
        margin-top: 0;
        margin-bottom: 0px!important;
    }

    .title-subcontainer{
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
        margin-bottom: 30px;
    }

    .list {
        list-style: none;
    }

    .list>li {
        font-size: 16px;
        color: #726659;
    }

    a { color: #94a90e ; }

    a:hover { color: #23527c !important; }

    hr {
        margin-top: 20px;
        margin-bottom: 20px;
        border: 0;
        border-top: 1.8px solid #e5e4e4 !important;
    }

    svg {
        display: inline !important;
    }

    .color-purple{
        color: #810042!important;
    }
</style>