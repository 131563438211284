<template>
  <footer  id="footer-conatiner">
    <div class="sm:px-20 sm:py-5 p-5">
      <div class="grid grid-cols-1 sm:grid-cols-3 sm:gap-24 my-5">

            <!--Comunicate con nosotros  -->
            <div>
                <h4 class="text-2xl text-left" style="font-family: 'Roboto';">Comunicate con nosotros</h4>
                <div style="display:flex">
                    <div class="liner-yellow"></div>
                    <div class="liner-white"></div>
                </div>

                <div class="mt-5">
                    <!-- Ubicacion  -->
                    <div style="display :flex" class="my-5" >
                        <p class="mx-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                            </svg>
                        </p>
                        <p class="text-sm">
                            Bahía San Carlos #1056, Colonia Prados del Tepeyac, C.P.  85150,
                            Cajeme, Ciudad Obregón, Sonora.
                        </p>
                    </div>
                    <!-- Final de ubicacion -->
                    <!-- Telefono -->
                     <div style="display :flex" class="my-5">
                        <p class="mx-2">
                          <img src="@/assets/celular.svg" alt="" style="width:15px;" loading="lazy">
                        </p>
                        <p class="text-sm mt-1">
                            (644) 416-4346
                        </p>
                    </div>
                    <!-- Final Telefono -->

                        <!-- Horario -->
                     <div style="display :flex" class="my-5">
                        <p class="mx-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                        </svg>
                        </p>
                        <p class="text-sm text-left" style="margin-top:-5px">
                            Horario de atención de 8:00 A.M a 3:00 P.M de Lunes a Viernes
                        </p>
                    </div>
                    <!-- Horario -->


                    <!-- Correo -->
                     <div style="display :flex" class="my-5">
                        <p class="mx-2">
                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                            </svg>
                        </p>
                        <p class="text-sm">
                            clientes@foncabsa.com
                        </p>
                    </div>
                    <!-- Final Correo -->
                    
                </div>
            </div>
            <!--Fin Comunicate con nosotros  -->
            <!-- Mapa de Sitio -->
             <div>
                <h4 class="text-2xl text-left" style="  font-family: 'Roboto';">Mapa de Sitio</h4>
                <div style="display:flex">
                    <div class="liner-yellow"></div>
                    <div class="liner-white"></div>
                </div>

                <div class="mt-5">
                   <div class="grid sm:grid-cols-2" >
                       <!-- Primera sección -->
                            <div>
                                    <!-- Inicio -->
                            <div style="display :flex" class="my-5">
                                <p class="mx-2">
                                <img src="@/assets/flecha.svg" alt="" style="width:10px;" loading="lazy">
                                </p>
                               <router-link to="/" class="router-link" >
                                    <p class="text-sm">
                                    Inicio
                                </p>
                               </router-link>
                            </div>
                            <!-- Fin Inicio -->
                            <!-- Préstamo simple -->
                            <div style="display :flex" class="my-5">
                                <p class="mx-2">
                                 <img src="@/assets/flecha.svg" alt="" style="width:10px;" loading="lazy">
                                </p>
                                <router-link to="/prestamo-simple" class="text-sm router-link">
                                    Préstamo Simple
                                </router-link>
                            </div>
                            <!-- Fin Préstamo simple  -->
                            <!-- Aviso de privacidad -->
                            <div style="display :flex" class="my-5">
                                <p class="mx-2">
                                  <img src="@/assets/flecha.svg" alt="" style="width:10px;" loading="lazy">
                                </p>
                                <a href="https://cabsacloud.ams3.digitaloceanspaces.com/store/FONSNTE/assets/FONCABSA%20-%20Aviso%20de%20Privacidad%20INTEGRAL.pdf" class="router-link" target="_blank">
                                    <p class="text-sm">
                                        Aviso de privacidad
                                    </p>
                                </a>
                            </div>
                            <!-- Aviso de privacidad   -->
                            <!-- Derechos ARCO -->
                            <div style="display :flex" class="my-5">
                                <p class="mx-2">
                                  <img src="@/assets/flecha.svg" alt="" style="width:10px;" loading="lazy">
                                </p>
                                <a class="text-sm router-link" href="https://cabsacloud.ams3.digitaloceanspaces.com/store/FONSNTE/assets/FONCABSA_FORMATO_ARCO.pdf" target="_blank">
                                    Derechos ARCO
                                </a>
                            </div>
                            <!-- Derechos ARCO   -->
                            </div>
                        <!-- Fin de primera sección -->

                    <!-- Segunda sección -->
                            <div>
                                <!-- Nosotros -->
                                <!-- <div style="display :flex" class="my-5">
                                    <p class="mx-2">
                                          <img src="@/assets/flecha.svg" alt="" style="width:10px;" loading="lazy">
                                    </p>
                                    <p class="text-sm">
                                            Nosotros
                                    </p>
                                </div> -->
                                <!-- Fin Nosotros -->
                                 <!-- Contacto -->
                                <div style="display :flex" class="my-5">
                                 
                                    <p class="mx-2">
                                          <img src="@/assets/flecha.svg" alt="" style="width:10px;" loading="lazy">
                                    </p>
                                    <router-link to="/contacto" class="router-link"   >
                                    <p class="text-sm">
                                            Contacto
                                    </p>
                                    </router-link>
                                </div>
                                <!-- Fin Contacto -->
                                   <!-- Aviso de Fraude -->
                                <div style="display :flex" class="my-5">
                                    <p class="mx-2">
                                         <img src="@/assets/flecha.svg" alt="" style="width:10px;" loading="lazy">
                                    </p>
                                    <router-link to="/aviso-de-fraude" class="router-link">
                                        <p class="text-sm">
                                            Aviso de Fraude
                                        </p>
                                    </router-link>
                                </div>
                                <!-- Fin Aviso de Fraude -->
                                    <!-- Programas de Educación Financiera -->
                                <div style="display :flex" class="my-5">
                                    <!-- <p class="mx-2">
                                          <img src="@/assets/flecha.svg" alt="" style="width:10px;" loading="lazy">
                                    </p>
                                    <a class="router-link" >
                                        <p class="text-sm">
                                            Programas de Educación Financiera
                                        </p>
                                    </a> -->
                                     <p class="mx-2">
                                          <img src="@/assets/flecha.svg" alt="" style="width:10px;" loading="lazy">
                                    </p>
                                    <a class="router-link text-left">
                                        <p class="text-sm">
                                                Programas de Educación Financiera
                                        </p>
                                    </a>
                                </div>
                                <div style="display :flex" class="my-5">
                                    <p class="mx-2">
                                          <img src="@/assets/flecha.svg" alt="" style="width:10px;" loading="lazy">
                                    </p>
                                    <router-link to="/personal/login" class="router-link">
                                        <p class="text-sm">
                                                Administradores
                                        </p>
                                    </router-link>
                                </div>
                                <!-- Fin Programas de Educación Financiera -->
                            </div>
                        <!-- Fin de Segunda sección -->

                   </div>
                </div>
            </div>
            <!-- Fin Mapa de Sitio -->
            <!-- Empresas de grupo cabsa -->
              <div>
                <h4 class="text-2xl text-left" style="  font-family: 'Roboto';">Empresas de Grupo Cabsa</h4>
                <div style="display:flex">
                    <div class="liner-yellow"></div>
                    <div class="liner-white"></div>
                </div>

                <div class="mt-5">
                   <div class="grid sm:grid-cols-2" >
                       <!-- Primera sección -->
                            <div>
                             <!-- Grupo Cabsa -->
                            <div style="display :flex" class="my-5">
                                <p class="mx-2">
                                  <img src="@/assets/flecha.svg" alt="" style="width:10px;" loading="lazy">
                                </p>
                                <a class="text-sm router-link" href="https://www.grupocabsa.com/" target="_blank">
                                    Grupo Cabsa
                                </a>
                            </div>
                            <!-- Fin Grupo Cabsa -->
                             <!-- Grupo Línea Magisterial -->
                            <div style="display :flex" class="my-5">
                                <p class="mx-2">
                                 <img src="@/assets/flecha.svg" alt="" style="width:10px;" loading="lazy">
                                </p>
                                <a class="text-sm router-link" href="https://www.lineamagisterial.com/" target="_blank">
                                    Línea Magisterial
                                </a>
                            </div>
                            <!-- Fin Línea Magisterial -->
                            </div>
                        <!-- Fin de primera sección -->

                    <!-- Segunda sección -->
                            <div>
                                <!-- Cabsa Seguros -->
                                <div style="display :flex" class="my-5">
                                    <p class="mx-2">
                                         <img src="@/assets/flecha.svg" alt="" style="width:10px;" loading="lazy">
                                    </p>
                                    <a class="text-sm router-link" href="https://www.cabsaseguros.com/" target="_blank">
                                            Cabsa Seguros
                                    </a>
                                </div>
                            </div>
                                <!-- Fin Cabsa Seguros -->
                        <!-- Fin de Segunda sección -->

                   </div>
                </div>
            </div>
            <!-- Empresas de grupo cabsa -->
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
#footer-conatiner {
  background: #821740;
  color: white;
  font-family: RobotoLight;
  margin: 0;
}

.liner-white{
    width: 100%;
    margin-top: 1px;
    background: white;
    height: 3px;
}
.liner-yellow{
   width: 25%;
    background: #f8c603;
    height: 5px; 
}

.router-link{
    color: white;
}

img {
    width: 10px;
    min-width: 10px;
}


</style>
