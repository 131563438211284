import api from '@/api/api'

const getMenuOptions = async () => {
    const resource = `/json/menu_options.json`;

    const params = {
        baseURL: 'hostLocalWeb'
    }

    const config = {}

    try {
        return await api.httpGet(resource, params, config)
    } catch (error) {
        throw error;
    }
}

export default {
    getMenuOptions
}