<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    class="
      relative
      bg-white
      border-b-1
      md:border-b-2
      border-gray-50
      md:border-gray-50
    "
  >
    <div
      class="
        max-w-7xl
        lg:max-w-7xl
        mx-auto
        mt-1
        px-4
        sm:px-6
        hidden
        md:block
        lg:block
        xl:block
        xxl:block
      "
    >
      <div
        class="
          flex
          justify-between
          items-center
          py-4 lg:py-6
          md:justify-start md:space-x-10
        "
      >
        <div class="flex justify-start lg:w-0 lg:flex-1 hidden lg:block">
          <router-link to="/">
            <span class="sr-only roboto-regular">FONCABSA</span>
            <img
              class="h-8 w-auto sm:h-10 animate-fade"
              src="@/assets/logo-heaedr.png"
              alt="FONCABSA"
              loading="lazy"
            />
          </router-link>
        </div>

        <nav class="hidden md:flex space-x-10 md:space-x-6 ">
          <div v-for="(item, index) in items" :key="index" :class="relative" class="animate-fade">
            <router-link
              :to="item.uri"
              v-if="item.options.length == 0 && item.router_link && item.show"
              class="item hover:text-gray-900"
            >
              {{ item.text }}
            </router-link>
            <a
              :href="item.uri"
              v-if="item.options.length == 0 && !item.router_link"
              class="item hover:text-gray-900"
            >
              {{ item.text }}
            </a>

            <button
              v-if="item.options.length > 0"
              @click="displayInput(item.name)"
              type="button"
              :name="item.name"
              class="
                item
                group
                bg-white
                rounded-md
                inline-flex
                items-center
                hover:text-gray-900
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-indigo-500
              "
              aria-expanded="false"
            >
              <span>{{ item.text }}</span>
              <svg
                class="text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>

            <div v-show="item.display" :class="item.class_options">
              <div
                class="
                  dropdown-menu
                  rounded-md
                  shadow-lg
                  ring-1 ring-black ring-opacity-5
                  overflow-hidden
                "
              >
                <div
                  class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-6"
                >
                  <router-link
                    v-show="!option.same_page"
                    :to="option.uri"
                    v-for="(option, index) in item.options"
                    :key="index"
                    class="
                      -m-4
                      p-2
                      flex
                      items-start
                      rounded-lg
                      hover:bg-gray-50
                    "
                  >
                    <div class="ml-4">
                      <p class="item">{{ option.text }}</p>
                    </div>
                  </router-link>

                  <a
                    v-show="option.same_page"
                    @click="showMenu = false"
                    :href="option.uri"
                    v-for="(option, index) in item.options"
                    :key="index"
                    class="
                      -m-4
                      p-2
                      flex
                      items-start
                      rounded-lg
                      hover:bg-gray-50
                      item
                      text-base
                      font-medium
                    "
                  >
                    {{ option.text }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>

    <div class="header shadow-md">
      <div class="bg-mobile-menu" v-show="showMenu"></div>
      <div
        class="
          flex
          justify-between
          items-center
          md:hidden
          lg:hidden
          xl:hidden
          2xl:hidden
        "
      >
        <img
          src="@/assets/back-arrow.svg"
          alt="Ir atrás"
          @click="goBack()"
          class="back-arrow-menu-mobile cursor-pointer"
          loading="lazy"
        />

        <div class="box-logo">
          <router-link to="/">
            <img src="@/assets/logo-heaedr.png" class="header-img" alt="" loading="lazy" />
          </router-link>
        </div>

        <div class="box-hamburger-menu" @click="showMenu = !showMenu">
          <span class="hamburger-menu"></span>
          <span class="hamburger-menu"></span>
          <span class="hamburger-menu"></span>
        </div>
      </div>
      <nav class="mobile accordion-menu" v-show="showMenu">
        <div
          v-for="(item, index) in items"
          :key="index"
          :class="{
            'hover:bg-darkCherry': item.options.length == 0,
          }"
        >
          <router-link
            v-if="item.options.length == 0 && item.router_link"
            :to="item.uri"
          >
            {{ item.text }}
          </router-link>
          <a
            :href="item.uri"
            v-if="item.options.length == 0 && !item.router_link"
          >
            {{ item.text }}
          </a>

          <div
            v-if="item.options.length > 0"
            @click="displayInput(item.name)"
            :to="item.uri"
          >
            {{ item.text }}

            <!-- <svg
            class="text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg> -->
          </div>

          <div v-if="item.options.length > 0 && item.display">
            <router-link
              v-show="!option.same_page"
              :to="option.uri"
              v-for="(option, index) in item.options"
              :key="index"
            >
              {{ option.text }}
            </router-link>
            <a
              v-show="option.same_page"
              @click="showMenu = false"
              :href="option.uri"
              v-for="(option, index) in item.options"
              :key="index"
            >
              {{ option.text }}
            </a>
          </div>
        </div>
      </nav>
    </div>
    <!--
      Mobile menu, show/hide based on mobile menu state.

      Entering: "duration-200 ease-out"
        From: "opacity-0 scale-95"
        To: "opacity-100 scale-100"
      Leaving: "duration-100 ease-in"
        From: "opacity-100 scale-100"
        To: "opacity-0 scale-95"
    -->
  </div>
</template>
<script>
import { ref, onBeforeMount, getCurrentInstance, toRef } from "vue";
import { isAuth, getUserInfo } from "@/helpers/tokenauth";
import { useRouter } from "vue-router";

export default {
  name: "Menu",
  props: {
    msg: String,
    list: {
      type: Array,
      default: []
    }
  },
  setup(props) {
    let auth = ref(null);
    let showMenu = ref(false);
    let user = ref(null);

    const router = useRouter();
    const items = toRef(props, "list");
    console.log("🚀 ~ file: Menu.vue:291 ~ setup ~ items", items.value)

    let app = getCurrentInstance();

    onBeforeMount(() => {
      /* auth.value = isAuth();
      app = app.appContext.config.globalProperties;
      if (auth.value) {
        user.value = getUserInfo();
        updateItemBySession(items);
      } */
      console.log("🚀 ~ file: Menu.vue:291 ~ setup ~ items", items)
    });

    const updateItemBySession = (items) => {
      items.value.filter((item) => {
        if (item.name == "login") {
          item.text = app.$filters.formatCamelize(
            user.value.short_name.toString()
          );
          item.uri = `/${user.value.account}/principal`;
          return item;
        }
        if (item.name == "register") {
          item.show = false;
          return item;
        }
        return item;
      });
    };

    const displayInput = (name) => {
      let inputs = [];
      items.value.forEach((item) => {
        item.display =
          item.name == name && item.display == true
            ? false
            : item.name == name
            ? true
            : false;
        inputs.push(item);
      });
      items.value = inputs;
    };

    const goBack = () => {
      const currentRoute = router.currentRoute.value.fullPath;

      if (currentRoute == "/") return;
      router.go(-1);
    };

    return {
      items,
      displayInput,
      showMenu,
      user,
      goBack,
    };
  },
};
</script>
  
<style scoped>
nav {
  width: auto;
}

nav.mobile.accordion-menu,
.bg-mobile-menu {
  top: 63px;
}

nav.mobile.accordion-menu > div {
  padding: 15px 15px 15px 45px;
  border-bottom: 1px solid #e6e6e6;
  font-size: 16px;
}

nav.mobile.accordion-menu > div > div {
  display: flex;
  flex-direction: column;
}

nav.mobile.accordion-menu > div > div > a {
  padding: 10px 0px 0px 15px;
}

.item {
  color: #821740;
  font-family: "roboto-regular";
  font-size: 18px;
}

@media (min-width: 600px) and (max-width: 1080px) {
  .item {
    font-size: 65%;
  }
}

.ml-4 > p.item {
  font-size: 16px;
}

.item .item-down {
  color: #821740b8;
  /* font-weight: 500; */
}
.dropdown-menu {
  border-top: 4.5px solid rgb(130, 23, 64) !important;
}

.dropdown-menu > li > a {
  font-weight: 300;
}

.bg-mobile-menu {
  left: 0;
}

@media only screen and (max-width: 768px) {
  nav {
    width: 90%;
  }

  .header-img {
    margin-top: 16px;
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  nav.mobile.accordion-menu,
  .bg-mobile-menu {
    display: none;
  }
}

@media (min-width: 1500px) and (max-width: 2000px) {
  #menu {
    margin-top: 1%;
  }
}

.sticky_nav {
  position: fixed;
  display: block;
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: white;
}
</style>
